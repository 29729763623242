.about-content {
    display: flex;
    flex-direction: row;
}

.headshot {
    border-radius: 200px;
    border: 2px solid;
    height: 400px;
}

.icons {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
}

.icons button {
    border: none;
    background: none;
    cursor: pointer;
}

.icons a, 
.icons button {
    display: inline-block;
    padding: 30px;
    font-size: 2em;
    color: #b4673eff;
}

.icons a:hover, 
.icons button:hover {
    color: #d3cdcd;
}

.about-content_left,
.about-content_right {
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
}

.about-content_right span {
    font-size: clamp(50px, 10vw, 75px);
    display: block;
}

.about-content_right {
    font-size: 20px;
}

