.skills-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 84vw;
}

.skills-content_container {
    padding: 2rem;
    margin: 1rem;
    width: 25vw;
}

.skills-content_container hr {
    width: 100%;
    margin-bottom: 1rem;
}

.section-header {
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    font-size: 25px;
    font-weight: 400;
}

.skills-content_container li {
    list-style-type: circle;
    margin: 1.5rem;
    padding: 5px;
    color: #252627ff;
    font-size: 20px;
}
