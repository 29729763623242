@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sacramento);
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.App {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    outline: none;
    font-family: 'Montserrat';
}

body {
    min-height: 100vh;
    margin: 0;
}

.App {
    z-index: 1;
	background: linear-gradient(-45deg, #2b2d42, #b47b76ff, #efede7ff);
	background-size: 200% 200%;
    /* background-size: 150%; */
	width: 100%;
    /* height: 100vh; */
	-webkit-animation: animate 30s ease infinite;
	        animation: animate 30s ease infinite;
}

@-webkit-keyframes animate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes animate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media screen and (max-width: 700px) {
    .section__padding {
    padding: 3rem;
    }

    .section__margin {
    margin: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
    padding: 3rem 2rem;
    }

    .section__margin {
    margin: 2rem 1rem;
    } 
}

/* Removed usage of gradient-text but keeping here in case I want to use in the future */
/* .gradient-text {
    display: block;
    background-image: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
} */

.glassmorphism {
    background-color:  rgba( 255,255,255, 0.1); 
    -webkit-backdrop-filter: blur(60px); 
            backdrop-filter: blur(60px);
    border-radius: 10px;
    border: 1px solid rgba( 255,255,255, 0.05); 
    padding: 3rem;
}

.content {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.section-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #252627ff;
    padding: 50px;
}

.section-container h2 {
    display: flex;
    justify-content: center;
    font-size: 3.5rem;
    margin-bottom: 2rem;
    padding: 1rem;
}

.about-content {
    display: flex;
    flex-direction: row;
}

.headshot {
    border-radius: 200px;
    border: 2px solid;
    height: 400px;
}

.icons {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
}

.icons button {
    border: none;
    background: none;
    cursor: pointer;
}

.icons a, 
.icons button {
    display: inline-block;
    padding: 30px;
    font-size: 2em;
    color: #b4673eff;
}

.icons a:hover, 
.icons button:hover {
    color: #d3cdcd;
}

.about-content_left,
.about-content_right {
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
}

.about-content_right span {
    font-size: clamp(50px, 10vw, 75px);
    display: block;
}

.about-content_right {
    font-size: 20px;
}


.contact-form_container {
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 2em 3em;
    width: 90vw;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form input, textarea, button {
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
}

.contact-form input, textarea {
    width: 75%;
}

.contact-form button {
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #b4673eff;
    color: #fff;
    border: none;
    text-decoration: none;
}

.contact-form button:hover {
    opacity: .75;
}

.experience-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em;
    width: 90vw;
}

/* .experience-content h2 {
    display: flex;
    justify-content: center;
} */

.title {
    font-size: 15em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
}

.date {
    color: #252627ff;
}

#description {
    margin: 1.5em 0 2em 0;
}

.projects-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 84vw;
}

.grow {
    padding: 5px;
    height: 100px;
    width: 22%;
    position: relative;
    overflow: hidden;
    text-align: center;
    transition: all 1s ease-in-out;
}

.grow p {
    opacity: 0;
    background-color: rgba(239, 237, 231, 0.5);
    border-radius: 5px;
}

.grow:hover {
    height: 75vh;
    width: 75vw;
}

.grow:hover p,
.grow:hover .project_links a {
    opacity: 1;
    padding: 5px;
    margin: 1rem;
    transition: opacity 1s ease;
}

.projects-content_column {
    display: flex;
    flex-direction: column;
    height: 75vh;
    color: #f6f4efff;
    background-color:rgba(37, 38, 39, 0.533);
    overflow: scroll;
}

.project_links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: auto;
}

.project_links a {
    opacity: 0;
    padding: 5px;
    background-color: rgba(239, 237, 231, 0.7);
    color: #252627ff;
    font-weight: 600;
    border-radius: 5px;
    text-decoration: none;
}

.projects-content_column:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
}

.projects-content_column:nth-of-type(4) {
    border-radius: 0 8px 8px 0;
}


.skills-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 84vw;
}

.skills-content_container {
    padding: 2rem;
    margin: 1rem;
    width: 25vw;
}

.skills-content_container hr {
    width: 100%;
    margin-bottom: 1rem;
}

.section-header {
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    font-size: 25px;
    font-weight: 400;
}

.skills-content_container li {
    list-style-type: circle;
    margin: 1.5rem;
    padding: 5px;
    color: #252627ff;
    font-size: 20px;
}

.navbar {
    position: fixed;
    height: 90px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 2rem 6rem;
    opacity: 1;
    z-index: 1;
    transition: 0.3s ease-in;
    margin-bottom: 3rem;
}

.navbar-bg {
    background-color: var(--color-bg-3);
    color: #d3cdcd;
}

.nav-links_logo {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-family: 'Sacramento';
}

.nav-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav-links_container {
    display: flex;
    flex-direction: row;
}

.nav-links_container a, .nav-links_resume a {
    color: #d3cdcd;
    font-family: var(--font-family);
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 1rem;
    cursor: pointer;
}

.nav-links_resume a {
    padding: 0.5rem 1rem;
    background-color: #b4673eff;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
}

.nav-links_resume:hover {
    opacity: .75;
}

.nav-links_container a:hover {
    color: black;
    text-decoration: underline;
}

.banner {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 400px;
    width: 100%;
    z-index: 0;
}
:root {
    --color-bg: #f6f4efff;
    --color-bg-2: #6d514fff;
    --color-bg-3: rgb(69, 71, 74);

    --color-text: #252627ff;
    --color-text-2: #efede7ff;

    --gradient-text: radial-gradient(circle, rgba(199,147,142,1) 17%, rgba(147,81,46,1) 100%);
}
