.experience-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
    padding: 2em 3em;
    width: 90vw;
}

/* .experience-content h2 {
    display: flex;
    justify-content: center;
} */

.title {
    font-size: 15em;
    text-align: center;
    font-family: "Bebas Neue", sans-serif;
}

.date {
    color: #252627ff;
}

#description {
    margin: 1.5em 0 2em 0;
}
