@import url('https://fonts.googleapis.com/css2?family=Sacramento');

.navbar {
    position: fixed;
    height: 90px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 2rem 6rem;
    opacity: 1;
    z-index: 1;
    transition: 0.3s ease-in;
    margin-bottom: 3rem;
}

.navbar-bg {
    background-color: var(--color-bg-3);
    color: #d3cdcd;
}

.nav-links_logo {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-family: 'Sacramento';
}

.nav-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.nav-links_container {
    display: flex;
    flex-direction: row;
}

.nav-links_container a, .nav-links_resume a {
    color: #d3cdcd;
    font-family: var(--font-family);
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0 1rem;
    cursor: pointer;
}

.nav-links_resume a {
    padding: 0.5rem 1rem;
    background-color: #b4673eff;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
}

.nav-links_resume:hover {
    opacity: .75;
}

.nav-links_container a:hover {
    color: black;
    text-decoration: underline;
}
