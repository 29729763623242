@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.App {
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    outline: none;
    font-family: 'Montserrat';
}

body {
    min-height: 100vh;
    margin: 0;
}

.App {
    z-index: 1;
	background: linear-gradient(-45deg, #2b2d42, #b47b76ff, #efede7ff);
	background-size: 200% 200%;
    /* background-size: 150%; */
	width: 100%;
    /* height: 100vh; */
	animation: animate 30s ease infinite;
}

@keyframes animate {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media screen and (max-width: 700px) {
    .section__padding {
    padding: 3rem;
    }

    .section__margin {
    margin: 2rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
    padding: 3rem 2rem;
    }

    .section__margin {
    margin: 2rem 1rem;
    } 
}

/* Removed usage of gradient-text but keeping here in case I want to use in the future */
/* .gradient-text {
    display: block;
    background-image: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
} */

.glassmorphism {
    background-color:  rgba( 255,255,255, 0.1); 
    backdrop-filter: blur(60px);
    border-radius: 10px;
    border: 1px solid rgba( 255,255,255, 0.05); 
    padding: 3rem;
}
